
























































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import IFlatQuery from '../models/IFlatQuery'

const { mapGetters, mapActions } = createNamespacedHelpers('Queries')

export default Vue.extend({
  data() {
    return {
      activeItem: null,
      deleteItem: null,
      headers: [
        {
          text: 'Report Name',
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'text-no-wrap'
        },
        {
          text: 'Language',
          align: 'start',
          sortable: true,
          value: 'templateName',
          class: 'text-no-wrap'
        },
        {
          text: 'Last Generated',
          align: 'start',
          sortable: true,
          value: 'lastGeneratedOn',
          class: 'text-no-wrap'
        },
        {
          text: 'Refresh Required',
          align: 'start',
          sortable: true,
          value: 'updatesAvailable',
          class: 'text-no-wrap'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 1
        }
      ]
    }
  },
  components: {
  },
  computed: {
    ...mapGetters(['results', 'loading'])
  },
  methods: {
    ...mapActions({
      deleteQuery: 'deleteQuery',
      saveQuery: 'saveQuery',
      generateDocument: 'generateDocument'
    }),
    async onDeleteQuery(item: IFlatQuery) {
      if (
        await this.$confirm(`Are you sure you want to delete the report: ${item.name}?`, {
          title: 'Delete Report?'
        })
      ) {
        await this.deleteQuery(item)
      }
    }
  }
})
